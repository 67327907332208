export default {
  namespaced: true,
  state: {
    status: '', // COMPLETE, LOADING, OR ERROR
    detail: '',
  },
  actions: {
    CHANGE_STATUS: ({ commit }, status) => {
      commit('changeStatus', status)
    },
    CHANGE_DETAIL: ({ commit }, detail) => {
      commit('changeDetail', detail)
    },
  },
  getters: {
    show: state => state.status != 'COMPLETE' && state.status != '',
  },
  mutations: {
    changeStatus: function(state, status) {
      state.status = status
    },
    changeDetail: function(state, detail) {
      state.detail = detail
    },
  },
}
