<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="54.467" height="45.764" viewBox="0 0 54.467 45.764"><g :fill="color" transform="translate(-2783.676 9318.301)"><path d="M20.488,31.463c-2.9,0-6.227-.811-10.782-2.631L4.223,26.6l-.995-.42c-.737-.3-1.187-.468-1.192-.47l5.812-3.924,1.858.756c4.555,1.82,7.88,2.632,10.783,2.632a11.385,11.385,0,0,0,6.585-1.928L42,13.168l3.614,1.47.79.337c.424.176.76.309.967.391l.441.168-20.738,14A11.389,11.389,0,0,1,20.488,31.463Z" transform="translate(2786 -9314)" stroke-miterlimit="10" stroke-width="2"/><path d="M20.488,31.463c-2.9,0-6.227-.811-10.782-2.631L4.223,26.6l-.995-.42c-.737-.3-1.187-.468-1.192-.47l5.812-3.924,1.858.756c4.555,1.82,7.88,2.632,10.783,2.632a11.385,11.385,0,0,0,6.585-1.928L42,13.168l3.614,1.47.79.337c.424.176.76.309.967.391l.441.168-20.738,14A11.389,11.389,0,0,1,20.488,31.463Z" transform="translate(2786 -9305)" stroke-miterlimit="10" stroke-width="2"/><path d="M23.028.885c5.207-2.213,10.613-.053,16.915,4L44.89,8.119,45.6,8.6c.36.239.657.431.874.568l.4.249L23.848,19.2c-5.207,2.213-10.613.053-16.915-4L1.976,11.964h0l-.9-.6C.408,10.919,0,10.673,0,10.673Z" transform="translate(2786 -9308.056) rotate(-11)" stroke-miterlimit="10" stroke-width="2"/></g></svg>
</template>

<script>
import Vue from "vue";

export default {
  name: 'hamburger-icon',
  props: ['color']
}
</script>
