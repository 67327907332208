import Vue from 'vue'
import VueRouter from 'vue-router'
import Intro from '@/views/Intro.vue'
import store from '@/store'
//import FA from '@/analytics'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    async beforeEnter(to, from, next) {
      await store.dispatch('GET_GLOBAL_SINGLETON')
      next(`/map/${ store.state.currentMap.name_slug }`)
    },
  },
  {
    path: '/map/:name',
    props: (route) => ({
      name: route.params.name,
    }),
    name: 'map',
    component: () => import(/* webpackChunkName: "map" */ '../views/Map.vue'),
  },
  {
    path: '/detail/:id',
    props: (route) => ({
      id: route.params.id,
    }),
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/Detail.vue'),
  },
  {
    path: '/productgroup/:id',
    props: (route) => ({
      id: route.params.id,
    }),
    name: 'productgroup',
    component: () => import(/* webpackChunkName: "productgroup" */ '../views/Group.vue'),
  },
  {
    path: '/productmodel/:id',
    props: (route) => ({
      id: route.params.id,
    }),
    name: 'productmodel',
    component: () => import(/* webpackChunkName: "productmodel" */ '../views/ProductModel.vue'),
  },
  {
    path: '*',
    alias: '/404',
    props: (route) => ({
      id: route.params.id,
    }),
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  /*
  FA.collect({
    cd7: 'browse page',
  })
  */
  store.dispatch('loader/CHANGE_STATUS', 'COMPLETE')
  store.dispatch('loader/CHANGE_DETAIL', '')
  // Dispatch form info each page load.
  // store.dispatch('forms/SUBMIT_QUEUED_FORMS') // try to submit queued forms on every route
  store.dispatch("ui/CLOSE_MODALS")  // close modals on route chante
  next()
})

export default router
