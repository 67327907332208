<script>
import { mapState } from "vuex";
import HamburgerIcon from "@/components/icons/HamburgerIcon.vue";
import LayerIcon from "@/components/icons/LayerIcon.vue";

export default {
  name: "FooterNav",
  components: {
    HamburgerIcon,
    LayerIcon,
  },
  computed: {
    ...mapState(["breadcrumbs", "globalColorTheme"]),
  },
};
</script>

<template>
  <v-menu transition="scroll-y-transition" rounded="t-xl" >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-large
        color="primary"
        icon
        v-on="on"
        v-bind="attrs"
        :ripple="false"
      >
        <v-icon>$multiple_layers</v-icon>
      </v-btn>
    </template>
    <div class="navDrawer" :style="{ background: setGradient(globalColorTheme)}">
      <v-toolbar dark>
        
        <v-toolbar-title class="font-weight-bold">
          <v-icon class="mr-2" color="primaryText">$multiple_layers</v-icon> Navigation Menu
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-list dark width="350px">
        <v-list-item>
          <v-list-item-icon class="mr-2">
            <v-icon large color="white">mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            Choose a destination to start exploring
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="(crumb, index) in breadcrumbs" :key="index" text>
          <v-list-item-icon class="ml-1">
            <v-icon color="primaryText">$multiple_layers</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <router-link :to="`/${crumb.route}/${crumb.slug}`">
              {{ crumb.name
              }}</router-link></v-list-item-title
            ></v-list-item-content
          >
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<style lang="scss" scoped>

.navDrawer {
  overflow: hidden;
  position: relative;
}
.navDrawer::after {
  content: "" !important;
  position: absolute;
  top: 122px;
  height: 60%;
  border: 1px dashed hsla(0,0%,100%,.25) !important;
  left: 33px !important;
}

.v-list-item__title a {
  color: var(--v-primaryText-base);
  text-decoration: none;
}

::v-deep {
  .theme--dark.v-toolbar.v-sheet, .theme--dark.v-list {
    background: transparent !important;
  }
}
</style>