<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Alert',
    data() {
      return {
        window: {
          width: 0,
          height: 0
        }
      }
    },
    created() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    computed: {
      ...mapState(['globalColorTheme']),
      ...mapState('ui', {
        dialog: state => state.dialog,
      }),
      borderColor(){
        return this.dialog.isError ? "" : {borderTopColor: this.globalColorTheme.color2};
      }
    },
    methods: {
      close() {
        this.$store.dispatch('ui/ALERT', {
          show: false,
          msg: '',
          details: '',
          isError: false,
        })
      },
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      },
    },
  }
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog.show"
      persistent
      max-width="600px"
      :fullscreen="this.window.width <= 1264"
    >
      <v-card tile class="form-container" :class="{ 'form-container-error': this.dialog.isError,  'form-container-success': !this.dialog.isError}" :style="borderColor" >
        <v-card-title class="header pl-0 pb-1 mx-9">
          <span class="headline">{{ dialog.msg }}</span>
          <v-spacer></v-spacer>
          <span @click="close" class="text-right text-button"><v-icon>mdi-window-close</v-icon></span>
        </v-card-title>
        <v-card-text class="px-9 py-4">
          {{ dialog.details }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped lang="scss">
  @import '@/assets/sass/theme.scss';

  .form-container {
    border-top-style: solid;
    border-top-width: 6px;

    &.form-container-error {
      border-top-color: red;
    }
  }

  .header {
    border-bottom: solid 2px $black;
  }

  .title {
    border-bottom: solid 1px black;
    margin: 0 20px;
    padding-left: 0 !important;
    padding-bottom: 5px !important;
  }

</style>
