import axios from "axios";

/*

To ensure forms get submitted in the absence of an internet connection,
the data for each submission gets stored in localStorage.  Then we try
to submit all data in local storage in a batch on every page load.

*/

export default {
  namespaced: true,
  state: {
    contactUsForms: [],
    getQuoteForms: [],
  },
  actions: {
    QUEUE_GET_QUOTE: function ({
      commit
    }, formObject) {
      commit("queueGetQuote", formObject);
    },
    QUEUE_CONTACT_US: function ({
      commit
    }, formObject) {
      commit("queueContactUs", formObject);
    },
    SUBMIT_QUEUED_FORMS: async function ({
      commit, dispatch
    }) {
      try {
        var contactUrl = `${process.env.VUE_APP_CMS_BASE_URL}api/forms/submit/ContactUs?token=${process.env.VUE_APP_CMS_TOKEN}`;
        var quoteUrl = `${process.env.VUE_APP_CMS_BASE_URL}api/forms/submit/getQuote?token=${process.env.VUE_APP_CMS_TOKEN}`;
        var clientFormUrl = `${process.env.VUE_APP_FORM_ENDPOINT}`;
        var contactForms = this.getters["forms/contactForms"];
        var quoteForms = this.getters["forms/quoteForms"];

        if (this.getters["forms/contactForms"].length > 0) {
          contactForms.forEach((form, index) => {
            var clientFormBody = {
              "contact": [{
                "firstname": form.firstName,
                "lastname": form.lastName,
                "email": form.email,
                "phone": form.phone,
                "city": form.city,
                "state": form.state,
                "zip": form.zip,
                "questions": form.question,
                "site": "virtual-shop",
                "form": "contact-form"
              }]
            }
            axios
              .post(
                contactUrl, {
                  form
                }, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }
              )
              .then((entry) => {
                console.log("Success: ", entry);
                dispatch("ui/ALERT", {
                  show: true,
                  isError: false,
                  msg: "Thanks for submitting!",
                  details:
                    "Our sales representative will contact you soon.",
                }, { root: true });
                commit("clearContactForm", index);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
            axios
              .post(
                clientFormUrl,
                clientFormBody, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }
              )
              .then((entry) => {
                console.log("Success: ", entry);
                commit("clearContactForm", index);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
        }

        if (this.getters["forms/quoteForms"].length > 0) {          
          quoteForms.forEach((form, index) => {
            var clientFormBody = {
              "contact": [{
                "firstname": form.firstName,
                "lastname": form.lastName,
                "email": form.email,
                "phone": form.phone,
                "city": form.city,
                "state": form.state,
                "zip": form.zip,
                "questions": form.question,
                "product": form.slug,
                "site": "virtual-shop",
                "form": "request-quote"
              }]
            }
            axios
              .post(
                quoteUrl, {
                  form
                }, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }
              )
              .then((entry) => {
                console.log("Success: ", entry);
                dispatch("ui/ALERT", {
                  show: true,
                  isError: false,
                  msg: "Thanks for submitting!",
                  details:
                    "Our sales representative will contact you soon.",
                }, { root: true });
                commit("clearQuoteForm", index);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
            axios
              .post(
                clientFormUrl, clientFormBody, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                }
              )
              .then((entry) => {
                console.log("Success: ", entry);
                commit("clearQuoteForm", index);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
        }

        if (this.getters["forms/numQueuedForms"] === 0) {
          console.log("No forms to sync.");
        }
      } catch (err) {
        console.log(err); // no connection, display feedback
      }
    },
  },
  getters: {
    numQueuedForms: (state) => {
      return state.contactUsForms.length + state.getQuoteForms.length;
    },
    contactForms: (state) => {
      return state.contactUsForms;
    },
    quoteForms: (state) => {
      return state.getQuoteForms;
    },
  },
  mutations: {
    queueGetQuote: function (state, formObject) {
      state.getQuoteForms.push(formObject);
    },
    queueContactUs: function (state, formObject) {
      state.contactUsForms.push(formObject);
    },
    clearCachedForms: function (state) {
      state.getQuoteForms = [];
      state.contactUsForms = [];
    },
    clearContactForm: function (state, index) {
      state.contactUsForms.splice(index, 1);
    },
    clearQuoteForm: function (state, index) {
      state.getQuoteForms.splice(index, 1);
    },
  },
};