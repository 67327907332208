<script>
import { mapState } from 'vuex'
export default {
  name: 'Loader',
  computed: {
    ...mapState(['globalColorTheme']),
    ...mapState({
      status: state => state.loader.status,
      detail: state => `<b>${ state.loader.detail }</b>`
          }),
    dialog() {
      return this.$store.getters['loader/show']
    },
    statusColor() {
      if(this.status == 'ERROR' || this.status == '') {
        return '#FE0100'
      }
      if(this.status == 'COMPLETE') {
        return this.globalColorTheme.color2
      }
      if(this.status == 'LOADING') {
        return this.globalColorTheme.color2
      }
      return ''
    },
  },
  methods: {
    close() {
      if(this.status == 'ERROR') {
        this.$store.dispatch('loader/CHANGE_STATUS', '')
        this.$store.dispatch('loader/CHANGE_DETAIL', '')
      }
    }
  }
}
</script>

<template>
  <v-dialog
      v-model="dialog"
      persistent
      hide-overlay
      width="300"
    >
      <v-card
        :color="statusColor"
        dark
        class="py-4"
        @click="close"
      >
        <v-card-title>{{ status }}</v-card-title>
        <v-card-text>
          <v-progress-linear
            v-if="status != 'ERROR'"
            indeterminate
            color="white"
            class="mb-2"
          ></v-progress-linear>
          <div v-html="detail">
          </div>
          <v-btn class="mt-4" color="secondary" v-if="status == 'ERROR'">Close</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
</style>
