import '../../src/assets/sass/icons.scss';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Icon from '../components/IconComponent.vue';

Vue.use(Vuetify)


export default new Vuetify({
  theme: {
    options: { customProperties: true },
  },
  icons: {
    values: {
      download: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-download'}
      },
      welder: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-welder'}
      },
      pin: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-pin'}
      },
      checkbox_unchecked: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-checkbox-unchecked'}
      },
      chevron_left: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-chevron-left'}
      },
      chevron_right: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-chevron-right'}
      },
      contact: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-contact'}
      },
      down_arrow: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-down-arrow'}
      },
      gas_tanks: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-gas-tanks'}
      },
      grid: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-grid'}
      },
      multiple_layers: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-multiple-layers'}
      },
      quote: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-quote'}
      },
      single_layer: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-single-layer'}
      },
      store: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-store'}
      },
      arrow_top_right: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-arrow-top-right'}
      },
      arrow_right: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-arrow-right'}
      },
      arrow_left: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-arrow-left'}
      },
      arrow_bottom_right: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-arrow-bottom-right'}
      },
      single_tank: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-single-tank'}
      },
      weld_tip: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-weld-tip'}
      },
      air_separation: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-air-separation'}
      },
      roll: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-roll'}
      },
      i_beam: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-i-beam'}
      },
      sheets: {
        iconfont: 'Praxair-Icons',
        component: Icon,
        props: { icon: 'p-icon-sheets'}
      }
    }
  }
});

// var iconNames = [
// download,
// welder,
// pin,
// checkbox-unchecked,
// chevron-left,
// chevron-right,
// contact,
// down-arrow,
// gas-tanks,
// grid,
// multiple-layers,
// quote,
// single-layer,
// store,
// arrow-top-right,
// arrow-right,
// arrow-left,
// arrow-bottom-right,
// single-tank,
// weld-tip,
// air-separation,
// roll,
// i-beam,
// sheets,
// doc-xls,
// doc,
// doc-acrobat,
// checkbox-checked,
// close,
// tank-danger,
// ];

// function iconFactory(name){
//     name.map( (el, i) => { 
//     var slug = el.replace('_', '-');
  
//       iconset[el] = {
//                 iconfont: 'Praxair-Icons',
//             component: 'Icon',
//             props: { icon: `p-icon-${slug}`}
//       };
    
//       } );

//     }
    
//   var iconNames = ['download', 'welder', 'store', 'gas_tanks'];

//   console.log(iconFactory(name));