<script>
import { mapState } from "vuex";
import { required, alpha_spaces, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("email", email);

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("phonetest", {
  message: "Please enter a valid 7 digit phone number",
  validate: (val) => {
     return (/^[2-9]\d{2}-\d{3}-\d{4}$/.test(val) || /^[2-9]\d{2}\d{3}\d{4}$/.test(val));
  },
});

extend("ziptest", {
  message: "Please enter a valid US ZIP code",
  validate: (val) => {
    return /^\d{5}(-\d{4})?$/.test(val);
  },
});

extend("nametest", {
  message: "Please enter only letters, hyphens or apostrophes",
  validate: (val) => {
    return /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/.test(val);
  },
});

extend("alpha_spaces", alpha_spaces);

export default {
  name: "ContactUsForm",
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      city: "",
      state: "",
      zip: "",
      question: ""
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  created() {
    this.window.width = window.innerWidth;
    this.window.height = window.innerHeight;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapState(['globalColorTheme']),
    ...mapState("ui", {
      display: (state) => state.contactUsFormDisplay,
    }),
  },
  methods: {
    close() {
      this.$store.dispatch("ui/TOGGLE_CONTACT_US", false);
    },
    save() {
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        this.$store.dispatch("ui/TOGGLE_CONTACT_US", false);
        this.$store.dispatch("forms/QUEUE_CONTACT_US", {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          city: this.city,
          state: this.state,
          zip: this.zip,
          question: this.question,
        });
        this.$store.dispatch("forms/SUBMIT_QUEUED_FORMS")
        
        // Resetting Values
        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.reset();
        });
      });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    reset() {
      this.firstName = this.lastName = this.email = this.phone = this.city = this.state = this.zip = this.question = ""
    }
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="display"
      persistent
      max-width="600px"
      :fullscreen="this.window.width <= 1264"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card tile class="form-container" :style="{ borderTopColor: globalColorTheme.color2 }">
          <v-card-title class="header pl-0 pb-1 mx-9">
            <span class="headline">Contact Us</span>
            <v-spacer></v-spacer>
            <span @click="close" class="text-right text-button"
              ><v-icon>mdi-window-close</v-icon></span
            >
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="First name"
                      rules="required|nametest"
                    >
                      <v-text-field
                        :error-messages="errors"
                        label="First name *"
                        v-model="firstName"
                        hint="Your first name"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last name"
                      rules="required|nametest"
                    >
                      <v-text-field
                        :error-messages="errors"
                        label="Last name *"
                        v-model="lastName"
                        hint="Your last name"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        :error-messages="errors"
                        v-model="email"
                        label="Email *"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="phone"
                      rules="phonetest"
                    >
                      <v-text-field
                        :error-messages="errors"
                        v-model="phone"
                        label="Phone"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required|alpha_spaces"
                    >
                      <v-text-field
                        :error-messages="errors"
                        v-model="city"
                        label="City *"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required|alpha_spaces"
                    >
                      <v-text-field
                        :error-messages="errors"
                        v-model="state"
                        label="State *"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Zip"
                      rules="required|ziptest"
                    >
                      <v-text-field
                        v-model="zip"
                        label="Zip *"
                        :error-messages="errors"
                        required
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="question"
                      label="Questions"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <small>*indicates required field</small>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="align-center">
            <v-spacer></v-spacer>
            <v-btn text class="mb-10 mr-6" @click="close">Cancel</v-btn>
            <v-btn
              large
              color="primary"
              class="mb-10"
              @click="save"
              :disabled="invalid"
              >Submit</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </v-row>
</template>

<style scoped lang="scss">
@import "@/assets/sass/theme.scss";

.form-container {
  border-top: solid 6px red;
}
.header {
  border-bottom: solid 2px $black;
}

.title {
  border-bottom: solid 1px $black;
  margin: 0 20px;
  padding-left: 0 !important;
  padding-bottom: 5px !important;
}
</style>
