<script>
import { mapState } from "vuex";
import gsap from "gsap";
import Alert from "@/components/Alert.vue";
import ContactUs from "@/components/forms/ContactUs.vue";
import ContactIcon from "@/components/icons/ContactIcon.vue";
import Loader from "@/components/Loader.vue";
import Footer from "@/views/Footer.vue";


export default {
  name: "App",
  data () {
    return {
      dialog: false,
    }
  },
  components: {
    Alert,
    ContactUs,
    ContactIcon,
    Loader,
    Footer
  },
 
  computed: {
    ...mapState(["currentMap", "maps", "defaultMapId"]),
    ...mapState("ui", {
      modalDisplay: (state) => state.modalDisplay,
    }),
    routeClass() {
      let slug = this.$route.name;
      return slug;
    },
    isMap() {
      if (this.$route.name == "map") {
        return true;
      } else {
        return false;
      }
    },
    isDefault(){
      return this.currentMap._id === this.defaultMapId;
    },
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    toggleDialog() {
      let orientation = window.orientation;

      this.isMobile() && (orientation == 0 || orientation == 180) ? this.dialog = true : this.dialog = false;
    },
  },
  async mounted() {
    document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));
    await this.$store.dispatch("GET_GLOBAL_SINGLETON");
    await this.$store.dispatch("GET_MAP");

    // Add event listeners for screen orientation change.
    let vm = this
    window.addEventListener('orientationchange', function() {
      vm.toggleDialog();
    });
    window.addEventListener('resize', function() {
      vm.toggleDialog();
    });

    // Check for mobile and portrait orientation on mount.
    this.toggleDialog();
  },
  unmounted() {
    let vm = this
    window.removeEventListener('orientationchange', function($event) {
      vm.toggleDialog($event);
    });
    screen.orientation.removeEventListener('change', function($event) {
      vm.toggleDialog($event);
    });
    window.removeEventListener('resize', function($event) {
      vm.toggleDialog($event);
    });
  }
};
</script>

<template>
  <v-app id="app" :class="[routeClass, this.currentMap.name_slug, {defaultMap: isDefault}]">
    <Loader />
    <v-dialog
      v-model="dialog"
      width="450"
    >
      <v-card
        class="pa-8"
        color="#005591"
      >
        <v-card-title class="white--text text-h5 light">
          Portrait Orientation Detected
        </v-card-title>
        <hr class="mx-6"/>
        <v-card-text class="white--text pt-4">
          <p class="">This content is best experienced with your device in landscape mode. Please rotate your device for optimal display.</p>
        </v-card-text>
        <div class="d-flex justify-center">
          <v-img src="../public/img/icons/flipPhoneIcon.png" max-width="250" height="auto"></v-img>
        </div>
      </v-card>
    </v-dialog>
    <v-main  :class="{ blur: modalDisplay }">
      <!-- Whether or not to use style="height: 100%"? -->
      <v-container
        fluid
        class="pa-0 ma-0 align-start text-center"
        style="height: 100%"
      >
        <router-view :isDefault="isDefault" />
        <Alert />
        <ContactUs />
      </v-container>
    </v-main>
  <Footer :logo="$store.state.logo" />

  </v-app>
</template>

<style lang="scss">
@import "@/assets/sass/theme.scss";

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  height: 100%;
}
@media only screen and (min-width: 1264px) {
  .blur {
    filter: blur(5px);
    transform: scale(1.2);
    pointer-events: none;
  }
}

#app {
  font-family: "LindeDax", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--v-primary-base);
  height: 100%;
  position: relative;
  overflow: hidden;
}

.v-application .v-application--wrap {
  height: 100%;
  justify-content: flex-start;
  min-height: 100%; 
}

// // setting up Main and footer flex
.v-main {
  flex: 1 1 auto !important;
  overflow: hidden;
  height: auto;
}


@media ( max-width: 599px ){

}

</style>
