<script>
import FooterNav from "@/views/FooterNav.vue";

export default {
  name: "Footer",
  components: {
    FooterNav,
  },
  props: {
    logo: {
      type: String,
      default: "",
    },
  },
  computed: {
      currentRoute() {
    return this.$route.fullPath;
  },
  },

  methods: {
    showContactUs() {
      this.$store.dispatch("ui/TOGGLE_CONTACT_US", true);
    },
  },
};
</script>

<template>
  <v-footer app id="nav" color="white" class="py-2">
    <router-link to="/">
      <v-img width="214px" height="50px" contain :src="logo" alt="" />
    </router-link>
    <!-- Our we using queuedForms? -->
    <!-- <div class="queuedForms">
        {{ $store.getters["forms/numQueuedForms"] }} queued forms
      </div> -->
    <v-spacer></v-spacer>
    <div v-if="currentRoute !== '/map/home'" class="mr-5">
      <v-btn
        @click="$router.back()"
        elevation="1"
        dark
        fab
        small
        color="primary"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn @click="$router.back()" text color="primary">Back</v-btn>
    </div>

    <v-btn @click="showContactUs" elevation="1" dark fab small color="primary">
      <v-icon>$contact</v-icon>
    </v-btn>
    <v-btn class="mr-5" @click="showContactUs" text color="primary"
      >Contact Us</v-btn
    >

    <FooterNav />
  </v-footer>
</template>

<style lang="scss" scoped>
.v-btn--text {
  text-transform: none;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: normal;
}
</style>
