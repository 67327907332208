<script>
export default {
    name: 'icon',
    props: {
        icon: {
            type: String
        }
    }
}
</script>
<template>
    <i :class="icon" data-attr="seeme"></i>
</template>