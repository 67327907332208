export default {
  namespaced: true,
  state: {
    modalDisplay: false, // there is a modal or dialog being shown
    dialog: { // details to show in a popup alert dialog box...ie 'Form Submitted!', 'Error Processing Request'
      show: false,
      msg: '',
      details: '',
      isError: false,
    },
    getQuoteFormDisplay: false,
    contactUsFormDisplay: false,
  },
  actions: {
    TOGGLE_GET_QUOTE: ({ commit }, show) => {
      commit('toggleModal', show)
      commit('toggleGetQuote', show)
    },
    TOGGLE_CONTACT_US: ({ commit }, show) => {
      commit('toggleModal', show)
      commit('toggleContactus', show)
    },
    TOGGLE_MODAL: ({ commit }, show) => {
      commit('toggleModal', show)
    },
    ALERT: ({ commit }, dialog) => {
      commit('toggleModal', dialog.show)
      commit('toggleDialog', dialog)
    },
    CLOSE_MODALS: ({commit}) => {
      commit('toggleModal', false)
      commit('toggleGetQuote', false)
      commit('toggleContactus', false)
    }
  },
  mutations: {
    toggleGetQuote: function(state, show) {
      state.getQuoteFormDisplay = show
    },
    toggleContactus: function(state, show) {
      state.contactUsFormDisplay = show
    },
    toggleDialog: function(state, dialog) {
      state.dialog = dialog
    },
    toggleModal: function(state, show) {
      state.modalDisplay = show
    },
  },
}
