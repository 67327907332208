<script>
  import gsap from 'gsap'

  export default {
    name: 'Intro',
    data() {
      return {
        t1: null,
        done: false,
      }
    },

    // Reverse the order of pins for map 1 in CMS??? Roll benders 1st. Then Map 2 comes through.
    // Make the intro bg the same size as the maps. (check)
    // Replace the logo with an actual logo. (Logo is not being recognized)
    // Darken the Map 2 icon.
    async mounted() {
      this.t1 = gsap.timeline()
      this.t1.from('.bg', {
        duration: 1,
        scale: 1,
        opacity: 0,
      })
      this.t1.from('.logo', {
        duration: .4,
        opacity: 0,
        scale: 3,
      })
      .then(() => {
        this.done = true
        // Transition to Map1 after 0.5 seconds.
        setTimeout(() => { this.$router.push({ path: `/map/${ this.$store.state.currentMap.name }` }) }, 500)
      })
    }
  }
</script>

<template>
  <div class="intro">
    <v-row class="bg pa-0 ma-0 fill-height align-content-center">
      <v-col>
        <div class="logo">
          <img class="logo" :src="$store.state.logo" alt="Praxair_logo"/>
          <div v-html="$store.state.introText" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
  .intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .bg {
    background-image: linear-gradient(#009B74, #61C250);
    flex: 1 1 750px;
    width: 100%;
  }
  .logo {
    // color: white;
    // font-size: 56px;

    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }
</style>
