import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import C from './clients/cms.js' // $$client$$ is replaced from the CLIENT value in .env
import C2 from './clients/cms.js'
import ui from './modules/ui'
import forms from './modules/forms'
import loader from './modules/loader'
import router from '../router/index.js'

Vue.use(Vuex)

const Client = new C()

export default new Vuex.Store({
  plugins: [
    createPersistedState({ paths: ['forms.getQuoteForms', 'forms.contactUsForms'] }) // only store these state paths in localStorage
  ],
  state: {
    baseURL: process.env.VUE_APP_CMS_BASE_URL,
    globalColorTheme: {},
    secondaryColor: {},
    defaultMapId: '',
    maps: [],
    productGroups: {},
    productModels: {},
    currentMap: {
      name: '',
    },
    currentProductGroup: {},
    currentProductModel: {},
    introText: '',
    logoSecondary: '',
    breadcrumbs: []
  },
  actions: {
    GET_GLOBAL_SINGLETON: async function({ commit }) {
      const globals = await Client.getGlobalSingleton()
      commit('setGlobals', globals)
    },
    GET_PRODUCTS: async function({ commit }) {
      if(Object.keys(this.state.productGroups).length === 0) {
        this.dispatch('loader/CHANGE_STATUS', 'LOADING')
        let data = await Client.getProducts()
        this.dispatch('loader/CHANGE_STATUS', 'COMPLETE')
        commit('setProducts', data)
      }
    },
    SET_PRODUCT_GROUP: async function({ commit }, id) {
      await this.dispatch('GET_PRODUCTS')
      commit('setProductGroup', id)
    },
    SET_PRODUCT_MODEL: async function({ commit }, id) {
      await this.dispatch('GET_PRODUCTS')
      commit('setProductModel', id)
    },
    SET_CURRENT_MAP: async function({ commit }, name) {
      commit('setMap', this.getters['maps'].find((map) => {
        return map.name_slug == name
      }))
    },
    GET_MAP: async function({ commit }, name) {
      try {
        if(!this.getters['maps'].length) { // Load map data and preload images once
          this.dispatch('loader/CHANGE_STATUS', 'LOADING')
          this.dispatch('loader/CHANGE_DETAIL', 'Fetching maps')
          let maps = await Client.getMaps()
          this.dispatch('loader/CHANGE_STATUS', 'COMPLETE')
          this.dispatch('loader/CHANGE_DETAIL', '')
          commit('setMaps', maps)
        }
      }
      catch(err) {
        console.log(err)
        this.dispatch('loader/CHANGE_STATUS', 'ERROR')
        this.dispatch('loader/CHANGE_DETAIL', 'Error processing maps.')
      }
    },
    SET_BREADCRUMBS: async function({commit}, crumbs) {
      commit('setBreadcrumbs', crumbs );
    }
  },
  mutations: {
    setProducts: function (state, products) {
      state.productGroups = products.productGroups
      state.productModels = products.productModels
    },
    setProductGroup: function (state, id){
      if(state.productGroups.find(p => p.name_slug == id) === undefined ){
        router.push('/404')
      }
      state.currentProductGroup = state.productGroups.find(p => p.name_slug == id)
    },
    setProductModel: function (state, id){
      
      if( !state.productModels.find(p => p.name_slug == id) ) {
        router.push('/404')
      }
      state.currentProductModel = state.productModels.find(p => p.name_slug == id)
    },
    setMaps: function (state, maps){
      state.maps = maps.map(m => {
        if(m.pins && m.pins.length > 0) {
          let pins = m.pins.map(p => {
            return {
              ...p,
              x: parseInt(p.x),
              y: parseInt(p.y),
            }
          })
          return {
            ...m,
            pins,
          }
        }
        return m
      })
    },
    setMap: function (state, map){
      state.currentMap = map
    },
    setGlobals: function(state, globals) {
      state.globalColorTheme = globals.colorTheme
      state.secondaryColor = globals.secondaryColor
      state.currentMap = globals.defaultMap
      state.logo = `${ process.env.VUE_APP_CMS_BASE_URL }storage/uploads${ globals.logo.path }`
      state.logoSecondary = `${ process.env.VUE_APP_CMS_BASE_URL }${ globals.logo_secondary.path }`
      state.introText = globals.intro_text,
      state.defaultMapId = globals.defaultMap._id
    },
    setBreadcrumbs: function(state, crumbs){
      state.breadcrumbs = crumbs;
    }

  },
  getters:{
    maps: (state) => {
      return state.maps
    },
  },
  modules: {
    ui,
    forms,
    loader,
  },
})
