import axios from 'axios'
import store from '@/store'
import Preload from 'image-preload'

export default class CMS {
  constructor() {
    this.Client =  axios.create({
      baseURL: process.env.VUE_APP_CMS_BASE_URL,
      timeout: 5000,
    })
    this.Client.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        console.log(error)
        store.dispatch('loader/CHANGE_STATUS', `ERROR`)
        return Promise.reject(error)
      }
    )
  }

  async getGlobalSingleton() {
    const global = await this.Client.get(`api/singletons/get/global?populate=3`)
    //await Preload(maps.data.entries.map(m => { return `${ process.env.VUE_APP_CMS_BASE_URL }${ m.background_image.path }` }), { inBackground: true, toBase64: true, })

    return global.data
  }

  async getMaps() {
    const maps = await this.Client.get(`api/collections/get/map?populate=5&token=${ process.env.VUE_APP_CMS_TOKEN }`)
    //await Preload(maps.data.entries.map(m => { return `${ process.env.VUE_APP_CMS_BASE_URL }${ m.background_image.path }` }), { inBackground: true, toBase64: true, })
    await maps.data.entries.forEach(async m => {
      try {
        const img = new Image()
        img.src = `${ process.env.VUE_APP_CMS_BASE_URL }${ m.background_image.path }`
      }
      catch(err) {
       console.log(err)
      }
    })
    return maps.data.entries
  }

  async getProducts() {
    const productGroups = await this.Client.get(`api/collections/get/product_group?populate=7&token=${ process.env.VUE_APP_CMS_TOKEN }`)
    const productModels = await this.Client.get(`api/collections/get/product_model?populate=4&token=${ process.env.VUE_APP_CMS_TOKEN }`)

    return {
      productGroups: productGroups.data.entries,
      productModels: productModels.data.entries,
    }
  }

  async getPage(id) {
    const result = await this.Client.get(`api/collections/get/product-type?populate=3&token=${ process.env.VUE_APP_CMS_TOKEN }`)
    const page = result.data.find(r => r.id == id)
    return page
  }

}
